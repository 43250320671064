<template>
    <v-container fluid fill-height class="console-faq">
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">FAQ</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="faqs" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:[`item.createdAt`]="{ item }">
                        {{ item.createdAt.toDate() }}
                    </template>
                    <template v-slot:[`item.faq`]="{ item }">
                        <v-row @click="$set(item, 'show', !item.show)">
                            <v-col cols="auto" class="text-start">[{{ item.type }}] {{ item.subject }}</v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                                <v-icon v-else>mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <v-row v-show="item.show">
                                <v-col cols="12" class="text-start">
                                    <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                                </v-col>
                            </v-row>
                        </v-expand-transition>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="remove(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on }">
                        <v-btn bottom color="accent" dark fab fixed right @click="create">
                            <v-icon v-on="on">mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <v-card v-if="dialog">
                        <v-card-title>
                            <span v-if="!editItem._id" class="subtitle-1">FAQ 등록</span>
                            <span v-else class="subtitle-1">수정</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col class="py-0">
                                    <v-text-field v-model="editItem.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col class="py-0">
                                    <v-text-field v-model="editItem.sequence" label="순서" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="py-0">
                                    <v-select v-model="editItem.type" :items="typeItems" label="유형" persistent-placeholder hide-details class="mt-4"></v-select>
                                </v-col>
                                <v-col class="py-0">
                                    <v-select v-model="editItem.locale" :items="locales" item-text="text" item-value="value" label="언어" persistent-placeholder hide-details class="mt-4"></v-select>
                                </v-col>
                            </v-row>

                            <div class="caption mt-4 mb-1">내용</div>
                            <naver-smarteditor v-model="editItem.content"></naver-smarteditor>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        NaverSmarteditor
    },
    created(){
        this.init();
    },
    data: () => {
        return {
            showsSearch: false,
            dialog: false,

            page: 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            faqs: [],
            headers: [
                { text: '순서', width: 100, align: 'center', value: 'sequence' },
                { text: 'FAQ', align: 'center', value: 'faq' },
                { text: '작성일자', align: 'center', width: 120, value: 'createdAt' },
                { text: '언어', align: 'center', width: 60, value: 'locale' },
                { text: 'actions', align: 'center', width: 100, value: 'actions' }
            ],
            editItem: {
                id: null,
                type: '',
                subject: "",
                content: ""
            },
            defaultItem: {
                id: null,
                locale: "ko",
                type: '',
                subject: "",
                content: ""
            },
            typeItems: ["개인정보문의","홈페이지문의","이용문의","기타"],
            locales: [
                { text: "한국어", value: "ko" },
                { text: "영어", value: "en" },
                { text: "중국어", value: "cn" },
            ]
        };
    },
    methods: {
        async init(){
            await this.search();
        },

        async search(){
            var { summary, faqs } = await api.console.center.faqs.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                }
            });
            
            this.summary = summary;
            this.faqs = faqs;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },

        close() {
            this.dialog = false
        },

        create(){
            this.editItem = Object.assign({}, this.defaultItem);
            this.editItem.sequence = this.summary.totalCount + 1;
            this.dialog = true;
        },

        edit(faq) {
            this.editItem = Object.assign({}, faq);
            this.dialog = true;
        },

        validate(){
            try{
                if(!this.editItem.subject) throw new Error("제목을 입력해주세요");
                // if(!this.editItem.type) throw new Error("유형을 선택해주세요");
                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },

        async save() {
            if(this.validate()){
                this.editItem._id ? await api.console.center.faqs.put(this.editItem) : await api.console.center.faqs.post(this.editItem);
                await this.search();
                this.dialog = false;
            }
        },

        async remove(faq){
            if(confirm("해당 FAQ를 삭제하시겠습니까?")){
                await api.console.center.faqs.delete(faq);
                await this.search();
            }
        },
    },
    watch: {
        dialog(){
            if(!this.dialog){
                this.editedItem = Object.assign({}, this.defaultItem)
            }
        }
    }
}
</script>

<style>
.console-faq .v-data-table__mobile-row { height: auto !important; }
</style>
